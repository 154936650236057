import DataObjectParser from 'dataobject-parser';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function objectFilter(obj, fn) {
    return Object.fromEntries(Object.entries(obj).filter(([k, v], i) => fn(v, k, i)));
}

export function arrayUnique(array, key = 'id') {
    array = array.filter(e => e);
    return [...new Map(array.map(item => [item[key], item])).values()]
}

export function objectRemoveByKey(obj, value, key = 'id') {
    return objectFilter(obj, (v) => v[key] !== value);
}

export function class_basename(string) {
    return string.split('\\').pop();
}

export function objectToArray(obj, mapFn) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc.push(mapFn(key, value));
        return acc;
    }, []);
}


export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ucFirst(string) {
    return capitalFirstLetter(string);
}

export function sentenceCase(string) {
    string = string.replaceAll('_', ' ').replaceAll('-', ' ');
    return capitalFirstLetter(string.toLowerCase());
}

export function lcFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function countOccurrences(array) {
    const counter = {};

    array.forEach(ele => {
        if (counter[ele]) {
            counter[ele] += 1;
        } else {
            counter[ele] = 1;
        }
    });

    let ans = [];
    for (const [key, value] of Object.entries(counter)) {
        ans.push(`${key.toUpperCase()} (${value}) `);
    }

    return ans;
}


export function formatUld(uld, delimiter = ', ') {
    if (typeof uld !== 'string' || uld === '') {
        return '---';
    }

    const array = uld.split(delimiter).filter(e => e !== '');
    let count = {};
    let result = '';
    for (let i = 0; i < array.length; i++) {
        if (count[array[i]]) {
            count[array[i]] += 1;
        } else {
            count[array[i]] = 1;
        }
    }
    Object.keys(count).map(key => {
        result += key + '(' + count[key] + ') ';
    });
    return result;
}

export function toSelect(objectOrArray, nullLabel = 'Select an option', nullValue = '', emptyLabel = 'No options available') {
    if (Array.isArray(objectOrArray)) {
        if (objectOrArray.length === 0) {
            return [{label: emptyLabel, value: nullValue}];
        }

        return [{label: nullLabel, value: nullValue}, ...objectOrArray];
    }

    if (Object.keys(objectOrArray).length === 0) {
        return [{label: emptyLabel, value: nullValue}];
    }

    return {[nullValue]: nullLabel, ...objectOrArray};
}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function arrayUniqueByKey(array, key = 'id') {
    array = array.filter(e => e);
    return array.filter((e, i) => array.findIndex(a => a[key] === e[key]) === i);
}

export function removeParam(key, sourceURL) {
    if (sourceURL === null) {
        return null;
    }

    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

export function extractParam(key, sourceURL) {
    if (sourceURL === null) {
        return null;
    }

    let params = {};
    let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

    if (queryString !== "") {
        let params_arr = queryString.split("&");
        for (let i = 0; i < params_arr.length; i++) {
            let param = params_arr[i].split("=");
            params[param[0]] = param[1];
        }
    }

    return decodeURIComponent(params[key]);

}

export function extractParams(sourceURL) {
    if (sourceURL === null) {
        return null;
    }

    let params = {};
    let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        let params_arr = queryString.split("&");
        for (let i = 0; i < params_arr.length; i++) {
            let param = params_arr[i].split("=");
            params[param[0]] = param[1];
        }
    }
    return params;

}


export function secondsToDuration(seconds) {
    if (seconds === null) {
        return '00:00';
    }
    seconds = String(seconds);
    let negative = seconds.startsWith('-');
    seconds = seconds.replace('-', '');
    let hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    let minutes = String(Math.floor(seconds % 3600 / 60)).padStart(2, '0');

    return (negative ? '- ' : '') + `${hours}:${minutes}`;
}

export function durationToSeconds(duration) {
    if (duration === null) {
        return 0;
    }
    let negative = duration.startsWith('-');
    duration = duration.replace('-', '');
    let [hours, minutes] = duration.split(':');
    let seconds = parseInt(hours) * 3600 + parseInt(minutes) * 60;
    return (negative ? '- ' : '') + seconds;
}

export function arrayIncludesAny(array, values) {
    array = array.map((value) => value.toLowerCase());
    values = values.map((value) => value.toLowerCase());

    return values.some((value) => array.includes(value));
}

export function firstFromOptions(any) {
    if (Array.isArray(any)) {
        return any[0];
    }

    return Object.keys(any)[0];
}


export function blank(any) {
    if (Array.isArray(any)) {
        return any.length === 0;
    }

    return any === null || any === undefined || any === '';
}

export function filled(any) {
    return !blank(any);
}

export function anyBlank(...args) {
    return args.some(blank);
}

export function take(template, data = {}) {
    let ans = {}
    for (const key in template) {
        ans[key] = data[key] ?? template[key] ?? null;
    }
    return ans;
}

export function strAfter(str, after, $count = 1) {
    return str.split(after).slice($count).join(after);
}

export function toFormKit(options) {
    return options.map((option) => {
        return {value: option.value, label: option.label, attrs: {disabled: option?.disabled ?? false}};
    });

}

export function when(condition, value, defaultValue = null) {
    return condition ? value : defaultValue;
}

export function isValidJson(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {
        return false;
    }

    return true;
}

export function hasKey(obj, key) {
    return Object.keys(obj).includes(key);
}

export function ensureArray(value) {
    return (Array.isArray(value) ? value : [value]).filter(e => e);
}
